import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";

import mapImg from "../../assets/images/home/map.svg";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const Whoweare = () => {
  const { t } = useTranslation();
  const language = i18next?.language;

  return (
    <>
      <section className="section-padding whoweare-area">
        <Container>
          <Typography
            variant="h1"
            fontWeight="700"
            textTransform="uppercase"
            sx={{ mb: 1 }}
            textAlign={language === "ar" ? "right" : "left"}
          >
            {t(`location.heading`)}
          </Typography>
          <Typography
            variant="body1"
            sx={{ pb: 5 }}
            textAlign={language === "ar" ? "right" : "left"}
          >
            {t(`location.subHeading`)}
          </Typography>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={12} md={7}>
              <img src={mapImg} className="img-fluid" />
            </Grid>
          </Grid>
        </Container>
      </section>
    </>
  );
};

export default Whoweare;
