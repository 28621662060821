import themes from "./themes";
import { useSelector } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./assets/scss/styles.scss";
import "./i18Next/config";

// Layouts
import LandingLayout from "./layout/LandingLayout";
import MinimalLayout from "./layout/MinimalLayout";
import MainLayout from "./layout/MainLayout";

// Pages
import Home from "./view/pages/Home";
import Login from "./view/pages/Login";
import Dashboard from "./view/pages/Dashboard";
import StudyPlan from "./view/pages/StudyPlan";

function App() {
  const customization = useSelector((state) => state.customization);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <Router>
          <Routes>
            {/* Landing Page Route */}
            <Route
              path="/"
              element={
                <LandingLayout>
                  <Home />
                </LandingLayout>
              }
            />

            {/* Login Page Route */}
            <Route
              path="/login"
              element={
                <MinimalLayout>
                  <Login />
                </MinimalLayout>
              }
            />

            {/* Dashboard Page Route */}
            <Route
              path="/dashboard"
              element={
                <MainLayout>
                  <Dashboard />
                </MainLayout>
              }
            />

            {/* StudyPlan Page Route */}
            <Route
              path="/studyplan"
              element={
                <MainLayout>
                  <StudyPlan />
                </MainLayout>
              }
            />
          </Routes>
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
