import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Logo from "../../assets/images/logo.svg";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import i18Next from "../../i18Next/config";

function Navbar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElSearch, setAnchorElSearch] = useState(null); // For search popover
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md")); // Check for screen size

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleToggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  // Open search popover
  const handleSearchClick = (event) => {
    setAnchorElSearch(event.currentTarget);
  };

  // Close search popover
  const handleCloseSearchPopover = () => {
    setAnchorElSearch(null);
  };

  const openSearchPopover = Boolean(anchorElSearch);
  const id = openSearchPopover ? "simple-popover" : undefined;

  // Close drawer when a menu item is clicked
  const handleMenuItemClick = () => {
    handleDrawerClose();
  };

  const navigate = useNavigate();

  const handleLoginClick = () => {
    // navigate("/login");
    window.location.href = "http://173.230.134.14:3001/";
  };

  const [language, setLanguage] = useState("ar");
  const { t } = useTranslation();
  useEffect(() => {
    i18Next.changeLanguage(language);
  }, [language]);

  return (
    <AppBar position="static" className="Navbar">
      <Container>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 0 }}>
            <img src={Logo} alt="logo" className="logo" />
          </Box>

          {/* Navigation Menu for Large Screens */}
          {!isMobile && (
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {/* {pages.map((page) => (
                <Button
                  key={page.label}
                  component={Link} 
                  to={page.path} 
                  sx={{ m: 0, p: 0, color: "white", display: "block" }}
                  disableRipple
                >
                  {page.label}
                </Button>
              ))} */}
              <Button
                variant="contained"
                color="primary"
                onClick={handleLoginClick}
                sx={{ minWidth: "155px" }}
              >
                {t(`login`)}
              </Button>
              <Box sx={{ minWidth: 20, ml: 1 }}>
                <FormControl className={"languageChangeDropdown"} fullWidth>
                  <Select
                    id="demo-simple-select"
                    value={language}
                    onChange={(e) => setLanguage(e.target.value)}
                    sx={{
                      border: "1px solid #50BBCA",
                      color: "#50BBCA",
                      fontWeight: "700",
                    }}
                  >
                    <MenuItem value={"ar"}>Ar</MenuItem>
                    <MenuItem value={"en"}>En</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          )}

          {/* Right-aligned section for search and cart icons on small devices */}
        </Toolbar>
      </Container>

      {/* Drawer for Mobile Navigation */}
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={handleDrawerClose}
        sx={{
          "& .MuiDrawer-paper": {
            width: 240,
          },
        }}
      >
        {/* <List>
          {pages.map((page) => (
            <ListItem
              className="text-dark"
              button
              key={page.label}
              component={Link}
              to={page.path}
              disableRipple
              onClick={handleMenuItemClick}
            >
              <ListItemText className="text-dark">{page.label}</ListItemText>
            </ListItem>
          ))}
        </List> */}
      </Drawer>
    </AppBar>
  );
}

export default Navbar;
