import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import heroThumb from "../../assets/images/home/hero-thumb.png";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const Slider = () => {
  const { t } = useTranslation();
  const language = i18next?.language;

  return (
    <>
      <section className="hero-banner">
        <Container sx={{ height: "100%" }}>
          <Grid
            container
            sx={{ height: "100%", direction: language === "ar" && "rtl" }}
          >
            <Grid item xs={12} sm={6} md={6}>
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                }}
              >
                <div>
                  <Typography
                    variant="h1"
                    className="text-white"
                    fontWeight="800"
                    sx={{ mb: 3 }}
                  >
                    {t(`slider.empower`)}{" "}
                    <Box component="span" sx={{ display: "block" }}>
                      {t(`slider.learning`)}
                    </Box>
                  </Typography>
                  <Typography
                    className="text-white"
                    sx={{ fontSize: "22px", mb: 4 }}
                  >
                    {t(`slider.description`)}
                  </Typography>
                  <div>
                    <Button variant="contained" color="primary">
                      {t(`slider.create`)}
                    </Button>
                  </div>
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <img
                src={heroThumb}
                style={{ width: "100%", maxWidth: "100%" }}
              />
            </Grid>
          </Grid>
        </Container>
      </section>
    </>
  );
};

export default Slider;
