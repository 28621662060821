import { Box, Container, Typography } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import statisticsData from "../../i18Next/locales/en.json";
import { ReactComponent as LogoIcon } from "../../assets/images/icons/default-img.svg";

import { useTranslation } from "react-i18next";

const Statistics = () => {
  const { t } = useTranslation();

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024, // For tablets and smaller screens
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768, // For mobile devices
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="bg-primary section-padding statistics-area">
      <Container>
        <Typography
          variant="h1"
          fontWeight="700"
          className="text-white text-center"
          textTransform="uppercase"
          sx={{ pb: 5 }}
        >
          {t(`statistics.heading`)}
        </Typography>
        <Slider {...settings} className="statistics-slider">
          {statisticsData?.statistics?.slider?.map((slide, i) => (
            <Box
              component="div"
              className="text-center"
              key={i}
              sx={{
                "& svg path": {
                  stroke: "#fff !important",
                },
              }}
            >
              <LogoIcon sx={{ maxHeight: "155px", margin: "0px auto" }} />
              <Typography
                variant="h1"
                className="text-white"
                fontWeight="700"
                sx={{ mt: 3 }}
              >
                {t(`statistics.slider.${i}.number`)}
              </Typography>
              <Typography
                variant="h3"
                className="text-white"
                fontWeight="500"
                sx={{ mb: 2, mt: 0.5 }}
              >
                {t(`statistics.slider.${i}.heading`)}
              </Typography>
              <Typography variant="body1" className="text-white">
                {t(`statistics.slider.${i}.description`)}
              </Typography>
            </Box>
          ))}
        </Slider>
      </Container>
    </section>
  );
};

export default Statistics;
