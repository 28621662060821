import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import aboutThumb1 from "../../assets/images/home/about1.jpg";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

// Custom SVG for the next arrow
const NextArrow = ({ onClick }) => (
  <div className="custom-arrow custom-next-arrow" onClick={onClick}>
    <svg
      width="30"
      height="30"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 4l8 8-8 8"
        stroke="#404040"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

// Custom SVG for the previous arrow
const PrevArrow = ({ onClick }) => (
  <div className="custom-arrow custom-prev-arrow" onClick={onClick}>
    <svg
      width="30"
      height="30"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 4l-8 8 8 8"
        stroke="#404040"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

const About = () => {
  const { t } = useTranslation();
  const language = i18next?.language;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    centerMode: true,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024, // For tablets and smaller screens
        settings: {
          slidesToShow: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 768, // For mobile devices
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <>
      <section className="section-padding about-area">
        <Container>
          <Grid
            container
            sx={{
              ...(language === "ar" && {
                direction: "rtl",
              }),
            }}
          >
            <Grid item xs={12} sm={6} md={6}>
              <Box
                className="LMS-content"
                sx={{
                  ...(language === "ar"
                    ? {
                        textAlign: "right",
                        borderRight: "1px solid #404041",
                        "&::before": {
                          right: "-2.5px",
                        },
                      }
                    : {
                        borderLeft: "1px solid #404041",
                        "&::before": {
                          left: "-2.5px",
                        },
                      }),
                }}
              >
                <Typography
                  variant="h1"
                  fontWeight="700"
                  textTransform="uppercase"
                  sx={{ mb: 5 }}
                >
                  {t(`about.heading`)}
                </Typography>
                <Typography variant="body">{t(`about.description`)}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Slider {...settings} className="about-slider">
                <Box className="stacked-card">
                  <img src={aboutThumb1} className="img-fluid" />
                </Box>
                <Box className="stacked-card">
                  <img src={aboutThumb1} className="img-fluid" />
                </Box>
              </Slider>
            </Grid>
          </Grid>
        </Container>
      </section>
    </>
  );
};

export default About;
