import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import gpaIcon from '../../assets/images/icons/gpaIcon.svg'
import coursesIcon from '../../assets/images/icons/coursesIcon.svg'
import termIcon from '../../assets/images/icons/termIcon.svg'
import payedIcon from '../../assets/images/icons/payedIcon.svg'

import dashboardBanner from '../../assets/images/dashboardBanner.png'

function Dashboard() {
  return (
    <>
      <Typography variant='h4' fontWeight="700" sx={{mb:2}}>Welcome Nasser!</Typography>
      <Box component="section" className='dashboard-factarea'>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
                <Box component="div" className='dashboard-factbox'>
                  <Box component="div" className='icon'>
                    <img src={gpaIcon} className='img-fluid' />
                  </Box>
                  <Box component="div" className='info' sx={{mt:1}}>
                      <Typography variant='h5' textTransform="uppercase" sx={{mb:0}}>GPA</Typography>
                      <Typography variant='h4' textTransform="uppercase" fontWeight="700" className='text-primary'>3.8</Typography>
                  </Box>
                </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <Box component="div" className='dashboard-factbox'>
                  <Box component="div" className='icon'>
                    <img src={coursesIcon} className='img-fluid' />
                  </Box>
                  <Box component="div" className='info' sx={{mt:1}}>
                      <Typography variant='h5' textTransform="uppercase" sx={{mb:0}}>Courses</Typography>
                      <Typography variant='h4' textTransform="uppercase" fontWeight="700" className='text-primary'>5</Typography>
                  </Box>
                </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <Box component="div" className='dashboard-factbox'>
                  <Box component="div" className='icon'>
                    <img src={termIcon} className='img-fluid' />
                  </Box>
                  <Box component="div" className='info' sx={{mt:1}}>
                      <Typography variant='h5' textTransform="uppercase" sx={{mb:0}}>Term</Typography>
                      <Typography variant='h4' textTransform="uppercase" fontWeight="700" className='text-primary'>4TH</Typography>
                  </Box>
                </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <Box component="div" className='dashboard-factbox'>
                  <Box component="div" className='icon'>
                    <img src={payedIcon} className='img-fluid' />
                  </Box>
                  <Box component="div" className='info' sx={{mt:1}}>
                      <Typography variant='h5' textTransform="uppercase" sx={{mb:0}}>Payment status</Typography>
                      <Typography variant='h4' textTransform="uppercase" fontWeight="700" className='text-primary'>Payed</Typography>
                  </Box>
                </Box>
            </Grid>
          </Grid>
      </Box>
      <Typography variant='h4' fontWeight="700" sx={{my:3, mt:4}}>Check Out Our New Learning Options</Typography>
      <Box>
        <img src={dashboardBanner} className='img-fluid' />
      </Box>
    </>
  )
}

export default Dashboard