import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import chroniclesData from "../../i18Next/locales/ar.json";

import chroniclesThumb1 from "../../assets/images/home/chronicles1.jpg";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

// Custom SVG for the next arrow
const NextArrow = ({ onClick }) => (
  <div className="custom-arrow custom-next-arrow" onClick={onClick}>
    <svg
      width="30"
      height="30"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 4l8 8-8 8"
        stroke="white"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

// Custom SVG for the previous arrow
const PrevArrow = ({ onClick }) => (
  <div className="custom-arrow custom-prev-arrow" onClick={onClick}>
    <svg
      width="30"
      height="30"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 4l-8 8 8 8"
        stroke="white"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

// AppendDots component
const AppendDots = () => {
  const { t } = useTranslation();
  const language = i18next?.language;

  const slideTexts = chroniclesData?.chronicles?.discription?.map((v, i) =>
    t(`chronicles.discription.${i}`),
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => (
      <div style={{ width: "100%" }}>
        <ul> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        className="chronicles-pagination"
        dangerouslySetInnerHTML={{ __html: slideTexts[i] }}
      />
    ),
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div className="slider-container w-100">
      <Slider {...settings} className="chronicles-slider">
        {chroniclesData?.chronicles?.overviewInfo?.map((v, i) => (
          <div key={i}>
            <Grid
              container
              alignItems="flex-end"
              spacing={2}
              sx={{ direction: language === "ar" && "rtl" }}
            >
              <Grid item xs={12} sm={12} md={6}>
                <img src={chroniclesThumb1} className="img-fluid" />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Box
                  component="div"
                  className="chronicles-slide-info"
                  sx={{ textAlign: language === "ar" && "right" }}
                >
                  <Typography fontWeight="700" className="text-white">
                    {t(`chronicles.overviewInfo.${i}.priod`)}
                  </Typography>
                  <Typography variant="body1" className="text-white">
                    {t(`chronicles.overviewInfo.${i}.heading`)}
                  </Typography>
                  <Box component="div" className="overview-info-list">
                    <ul style={{ direction: language === "ar" && "rtl" }}>
                      {v?.lists?.length > 0 &&
                        v?.lists?.map((list, index) => (
                          <li key={index} className="text-white">
                            <Typography variant="body" className="text-white">
                              {t(`chronicles.overviewInfo.${i}.lists.${index}`)}
                            </Typography>
                          </li>
                        ))}
                    </ul>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        ))}
      </Slider>
    </div>
  );
};

// Chronicles component
const Chronicles = () => {
  const { t } = useTranslation();
  const language = i18next?.language;
  return (
    <>
      <section className="bg-primary section-padding chronicles-area">
        <Container>
          <Typography
            variant="h1"
            fontWeight="700"
            className="text-white"
            textTransform="uppercase"
            sx={{ pb: 4 }}
            textAlign={language === "ar" && "right"}
          >
            {t(`chronicles.heading`)}
          </Typography>
          <AppendDots />
        </Container>
      </section>
    </>
  );
};

export default Chronicles;
