import { Box, Container, Link, Stack, Typography } from "@mui/material";
import React from "react";
import footerLogo from '../../assets/images/logo.svg';
import footerSocial from '../../assets/images/home/socialIcon.svg';

function Footer() {
  return (
    <>
      <footer className="bg-lightprimary footer">
      <Container>
        <section className="footer-top">
            <Box sx={{ mb: 5 }}>
              <img src={footerLogo} alt="footer logo" />
            </Box>
            <Box>
              <Stack spacing={3}>
                <Link href="#" underline="none" className="footer-links">
                  <Typography>+966 123 456 789</Typography>
                </Link>
                <Link href="#" underline="none" className="footer-links">
                  <Typography>info@institution.sa</Typography>
                </Link>
                <Link href="#" underline="none" className="footer-links">
                  <Typography >Riyadh, Saudi Arabia</Typography>
                </Link>
              </Stack>
            </Box>
         
        </section>
        <section className="footer-bottom">
            <Box sx={{display: { xs: 'block', sm: 'flex' }, alignContent:"center", justifyContent:"space-between"}}>
              <Box component="div">
                <Typography>© [Institution Name] 2024. All rights reserved.</Typography>
              </Box>
              <Box component="div" className="footer-links">
                  <Box  sx={{display: { xs: 'block', sm: 'flex' }, justifyContent:"space-between", gap:"15px"}}>
                    <Link href="#" underline="none" className="footer-links">Data protection</Link>
                    <Link href="#" underline="none" className="footer-links">Legal notice</Link>
                    <Link href="#" underline="none" className="footer-links">Cookie-Settings</Link>
                  </Box>
              </Box>
              <Box component="div" className="text-right">
                  <img src={footerSocial} className="img-fluid" style={{height:"30px"}} />
              </Box>
            </Box>
        </section>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
