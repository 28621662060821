import React from 'react'
import Slider from '../../components/Home/Slider'
import About from '../../components/Home/About'
import Programs from '../../components/Home/Programs'
import Chronicles from '../../components/Home/Chronicles'
import LMSTimeline from '../../components/Home/LMSTimeline'
import Vision from '../../components/Home/Vision'
import Goals from '../../components/Home/Goals'
import Statistics from '../../components/Home/Statistics'
import Whoweare from '../../components/Home/Whoweare'
import Footer from '../../layout/LandingLayout/Footer'

function Home() {
  return (
    <>
        <Slider />
        <About />
        <Programs />
        <Chronicles />
        <LMSTimeline />
        <Vision />
        <Goals />
        <Statistics />
        <Whoweare />
    </>
  )
}

export default Home