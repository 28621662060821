import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import visionThumb1 from "../../assets/images/home/vision1.jpg";
import visionThumb2 from "../../assets/images/home/vision2.jpg";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

// Custom SVG for the next arrow
const NextArrow = ({ onClick }) => (
  <div className="custom-arrow custom-next-arrow" onClick={onClick}>
    <svg
      width="30"
      height="30"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 4l8 8-8 8"
        stroke="white"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

// Custom SVG for the previous arrow
const PrevArrow = ({ onClick }) => (
  <div className="custom-arrow custom-prev-arrow" onClick={onClick}>
    <svg
      width="30"
      height="30"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 4l-8 8 8 8"
        stroke="white"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

const Vision = () => {
  const { t } = useTranslation();
  const language = i18next?.language;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    //centerMode: true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024, // For tablets and smaller screens
        settings: {
          slidesToShow: 2,
          centerMode: true,
        },
      },
      {
        breakpoint: 768, // For mobile devices
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <section className="bg-secondary section-padding vision-area">
      <Container>
        <Grid
          container
          alignItems="flex-end"
          spacing={2}
          sx={{
            ...(language === "ar" && {
              direction: "rtl",
            }),
          }}
        >
          <Grid item xs={12} sm={12} md={6}>
            <div className="vision-slider-outer">
              <Slider {...settings} className="vision-slider">
                <div>
                  <img src={visionThumb1} className="img-fluid" />
                </div>
                <div>
                  <img src={visionThumb2} className="img-fluid" />
                </div>
              </Slider>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box component="div" className="vision-slide-info">
              <Typography
                variant="h1"
                component="h1"
                fontWeight="700"
                textTransform="uppercase"
                className="text-white"
                sx={{ mb: 2, ...(language === "ar" && { textAlign: "right" }) }}
              >
                {t(`vision.heading`)}
              </Typography>
              <Typography
                variant="body1"
                className="text-white"
                textAlign={language === "ar" && { textAlign: "right" }}
              >
                {t(`vision.description`)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default Vision;
