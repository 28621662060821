import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";

import goalImg from "../../assets/images/home/goal-thumb.jpg";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import goalsData from "../../i18Next/locales/en.json";

const Goals = () => {
  const { t } = useTranslation();
  const language = i18next?.language;

  return (
    <>
      <section className="section-padding goal-area">
        <Container>
          <Grid
            container
            alignItems="center"
            spacing={2}
            sx={{
              ...(language === "ar" && {
                direction: "rtl",
              }),
            }}
          >
            <Grid item xs={12} sm={12} md={6}>
              <Box
                className="plan-creditinfo"
                sx={{
                  minHeight: "375px !important",
                  ...(language === "ar"
                    ? {
                        textAlign: "right",
                        "&::before": {
                          right: "-2.5px",
                        },
                      }
                    : {
                        "&::before": {
                          left: "-2.5px",
                        },
                      }),
                }}
              >
                <Typography
                  variant="h1"
                  fontWeight="700"
                  textTransform="uppercase"
                  sx={{ mb: 2 }}
                >
                  {t(`goals.heading`)}
                </Typography>
                <Box
                  sx={{ mt: 2 }}
                  component="div"
                  className="overview-info-list"
                >
                  <ul style={{ direction: language === "ar" && "rtl" }}>
                    {goalsData?.goals?.description?.map((val, i) => (
                      <li>
                        <Typography variant="body">
                          {t(`goals.description.${i}`)}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <img src={goalImg} className="img-fluid" />
            </Grid>
          </Grid>
        </Container>
      </section>
    </>
  );
};

export default Goals;
