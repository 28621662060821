import React from "react";
import Header from "../LandingLayout/Header";
import Footer from "../LandingLayout/Footer";

const LandingLayout = ({ children }) => (
  <div>
    <Header />
        <main>{children}</main>
    <Footer />
  </div>
);

export default LandingLayout;
