import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import lmsTimelineData from "../../i18Next/locales/en.json";

const LMSTimeline = () => {
  const { t } = useTranslation();
  const language = i18next?.language;

  return (
    <>
      <section className="bg-lightprimary section-padding program-area">
        <Container>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={7}>
              <Typography
                variant="h1"
                fontWeight="700"
                textTransform="uppercase"
                textAlign="center"
                sx={{ mb: 5 }}
              >
                {t(`lmsTimeline.heading`)}
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Container>
          <Grid
            container
            alignItems="stretch"
            justifyContent="center"
            spacing={2}
          >
            {lmsTimelineData?.lmsTimeline?.overviewInfo?.map((overview, i) => (
              <Grid item xs={12} sm={6} md={4}>
                <Box component="div" className="overview-box">
                  <Box component="div" className="overview-thumb">
                    <img src={overview?.thumbnail} className="img-fluid" />
                  </Box>
                  <Box
                    component="div"
                    className="overview-info"
                    textAlign={language === "ar" && "right"}
                  >
                    <Typography variant="countTitle" fontWeight="700">
                      {overview?.number}
                    </Typography>
                    <Typography variant="h3" fontWeight="700">
                      {t(`lmsTimeline.overviewInfo.${i}.heading`)}
                    </Typography>
                  </Box>

                  <Box
                    component="div"
                    className="overview-box-hover"
                    textAlign={language === "ar" && "right"}
                  >
                    <Box
                      component="div"
                      sx={{
                        display: "flex",
                        alignItems: "flex-end",
                        height: "100%",
                      }}
                    >
                      <Box component="div" className="overview-info">
                        <Typography variant="countTitle" fontWeight="700">
                          {overview?.number}
                        </Typography>
                        <Typography variant="h3" fontWeight="700">
                          {t(`lmsTimeline.overviewInfo.${i}.heading`)}
                        </Typography>
                        <Box
                          sx={{ mt: 2 }}
                          component="div"
                          className="overview-info-list"
                        >
                          <ul style={{ direction: language === "ar" && "rtl" }}>
                            {overview?.items?.map((item, indexItem) => (
                              <li>
                                <Typography variant="body">
                                  <strong>
                                    {t(
                                      `lmsTimeline.overviewInfo.${i}.items.${indexItem}.heading`,
                                    )}
                                  </strong>
                                  {t(
                                    `lmsTimeline.overviewInfo.${i}.items.${indexItem}.subHeading`,
                                  )}
                                </Typography>
                              </li>
                            ))}
                          </ul>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </section>
    </>
  );
};

export default LMSTimeline;
