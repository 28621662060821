import { combineReducers } from 'redux';

// Example reducer (you can add your actual reducers here)
const initialState = {
  customization: {}
};

const customizationReducer = (state = initialState.customization, action) => {
  switch (action.type) {
    // Define your cases here
    default:
      return state;
  }
};

export default combineReducers({
  customization: customizationReducer,
  // Add other reducers here
});
